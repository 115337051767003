import { Controller } from '@hotwired/stimulus';
import M from '@materializecss/materialize/dist/js/materialize.min';

export default class extends Controller {
  static targets = ['productOptionSelect'];

  connect() {
    // ref: https://qiita.com/Kyo18/items/cac00086d272ddb1fe01
    $(this.productOptionSelectTarget).on('select2:select', function () {
      const event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });
  }

  template() {
    const productOptionId = document.getElementById(
      'contract_item_product_option_id'
    ).value;

    fetch(`/product_options/${productOptionId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        $('#contract_item_display_name_product_option').val(data.display_name);
        M.updateTextFields();

        $('#contract_item_product_id').empty();

        $('#contract_item_product_id').select2({
          data: [{ id: '', text: '' }].concat(
            data.products.map((product) => {
              return {
                id: product.id,
                text: `${product.code}: ${product.name}`,
              };
            })
          ),
        });
      })
      .catch((error) =>
        console.error('Error fetching product template:', error)
      );
  }
}
